import axios from "axios"

export default {
    state: {
        verify: [],
        verified: [],
        pending: [],
        performance: [],
    },

    getters: {
        allVerify: state => state.verify,
        allVerified: state => state.verified,
        allPending: state => state.pending,
        allPerformance: state => state.performance,
    },

    mutations: {
        setVerify: (state, payload) => state.verify = payload,
        setVerified: (state, payload) => state.verified = payload,
        setPending: (state, payload) => state.pending = payload,
        setPerformance: (state, payload) => state.performance = payload,
    },

    actions: {
        async fetchVerify({
            commit
        }) {
            const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/stats/verify`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            // console.log(response.data)
            commit('setVerify', response.data)
        },

        async fetchVerified({
            commit
        }) {
            const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/stats/verified`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            // console.log(response.data)
            commit('setVerified', response.data)
        },

        async fetchPending({
            commit
        }) {
            const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/stats/pending`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            // console.log(response.data)
            commit('setPending', response.data)
        },

        async fetchPerformance({
            commit
        }) {
            const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/stats/performance`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            })
            // console.log(response.data)
            commit('setPerformance', response.data)
        }
    }
}