import axios from "axios"

export default {
    state: {
        members: []
    },
    getters: {
        allMembers: state => state.members
    },
    mutations: {
        setMembers: (state, members) => {
            state.members = members
        },
        newMember: (state, member) => {
            state.members.unshift(member)
        },
        reviseMember: (state, member) => {
            const index = state.members.findIndex(item => item.id == member.id)
            if(index !== -1) {
                state.members.splice(index, 1, member)
            }
        },
        removeMember: (state, id) => {
            const index = state.members.findIndex(item => item.id == id)
            if(index !== -1) {
                state.members.splice(index, 1)
            }
        }
    },
    actions: {
        async fetchMembers({commit}) {
            const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/members`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`
                }
            })

            commit('setMembers', response.data)
        },

        async addMember({commit}, member) {
            const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/members`, member, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`
                }
            })
            console.log(response.data)
            commit('newMember', response.data)
        },

        async updateMember({commit}, member) {
            const response = await axios.put(`${process.env.VUE_APP_API_URL}/api/members/${member.id}`, member, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`
                }
            })

            commit('reviseMember', response.data)
        },

        async deleteMember({commit}, member) {
            await axios.delete(`${process.env.VUE_APP_API_URL}/api/members/${member.id}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`
                }
            })

            commit('removeMember', member.id)
        }
    }
}