import Vue from 'vue'
import Vuex from 'vuex'
import Agent from './modules/Agent'
import Member from './modules/Member'
import MemberLink from './modules/MemberLink'
import MemberStock from './modules/MemberStock'
import MemberVerification from './modules/MemberVerification'
import Stats from './modules/Stats'
import User from './modules/User'
import VerifySession from './modules/VerifySession'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Agent,
    Member,
    MemberLink,
    MemberStock,
    MemberVerification,
    Stats,
    User,
    VerifySession
  }
})
