import axios from "axios"

export default {
    state: {
        agents: []
    },
    getters: {
        allAgents: state => state.agents
    },
    mutations: {
        setAgents(state, agents) {
            state.agents = agents
        },
        newAgent(state, agent) {
            state.agents.unshift(agent)
        },
        reviseAgent(state, agent) {
            let index = state.agents.findIndex(item => item.id === agent.id)
            if (index != -1) {
                state.agents.splice(index, 1, agent)
            }
        },
        removeAgent(state, agent) {
            let index = state.agents.findIndex(item => item.id === agent)
            if (index != -1) {
                state.agents.splice(index, 1)
            }
        }
    },
    actions: {
        async fetchAgents({
            commit
        }) {
            const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/agents`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`
                }
            })

            // console.log(response.data)
            commit('setAgents', response.data)
        },

        async createAgent({
            commit
        }, agent) {
            const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/agents`, agent, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`
                }
            })

            commit('newAgent', response.data)
        },

        async updateAgent({
            commit
        }, agent) {
            const response = await axios.put(`${process.env.VUE_APP_API_URL}/api/agents/${agent.id}`, agent, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`
                }
            })

            commit('reviseAgent', response.data)
        },

        async deleteAgent({
            commit
        }, agent) {
            await axios.delete(`${process.env.VUE_APP_API_URL}/api/agents/${agent}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`
                }
            })

            commit('removeAgent', agent)
        }
    },
}