import axios from "axios"

export default {
    state: {
        member_verify: []
    },
    getters: {
        allMemberVerify: state => state.member_verify
    },
    mutations: {
        setMemberVerify(state, member_verify) {
            state.member_verify = member_verify
        },

        newMemberVerify(state, member_verify) {
            state.member_verify.unshift(member_verify)
        },

        reviseMemberVerify(state, member_verify) {
            let index = state.member_verify.findIndex(item => item.id == member_verify.id)
            
            if (index != -1) {
                state.member_verify.splice(index, 1, member_verify)
            }
        },

        removeMemberVerify(state, id) {
            let index = state.member_verify.findIndex(item => item.id == id)
            if (index != -1) {
                state.member_verify.splice(index, 1)
            }
        }
    },
    actions: {
        async fetchMemberVerify({
            commit
        }) {
            const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/member/verification`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`
                }
            })

            // console.log(response.data)
            commit('setMemberVerify', response.data)
        },

        async addMemberVerify({
            commit
        }, member) {
            const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/member/verify`, member, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`
                }
            })

            // console.log(response.data)
            commit('newMemberVerify', response.data)
            return response.data
        },

        async updateMemberVerify({commit}, member){
            let id = member.id || member.get('id')
            
            const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/member/verification/${id}`, member, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`
                }
            })

            // console.log(response.data)

            commit('reviseMemberVerify', response.data)
        },

        async deleteMemberVerify({commit}, member){
            await axios.delete(`${process.env.VUE_APP_API_URL}/api/member/verification/${member.id}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`
                }
            })

            commit('removeMemberVerify', member.id)
        }
    },
}