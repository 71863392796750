import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      title: 'Verification',
      requiresAuth: false
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Auth.vue'),
    meta: {
      requiredAuth: false,
      title: 'Login',
    }
  },
  {
    path: '/verify',
    name: 'Verify',
    component: () => import('../views/VerificationForm.vue'),
    meta: {
      requiredAuth: false,
      title: 'Member Verification',
    }
  },
  {
    path: '/console',
    name: 'Console',
    component: () => import('../views/Console.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('../views/console/Dashboard.vue'),
        meta: {
          requiredAuth: true,
          title: 'Dashboard',
        }
      },
      {
        path: 'users',
        name: 'Users',
        beforeEnter: (to, from, next) => {
          let role = window.localStorage.getItem('role')
          if(role == 'admin'){
            next()
          } else {
            router.push({
              path: '/login',
              name: 'Login',
              query: {
                message: 'You are not authorized to access this page, please login as admin to continue'
              }
            })
          }
        },
        component: () => import('../views/console/User.vue'),
        meta: {
          requiredAuth: true,
          title: 'Manage Users',
        }
      },
      {
        path: 'agents',
        name: 'Agents',
        beforeEnter: (to, from, next) => {
          let role = window.localStorage.getItem('role')
          if(role == 'admin'){
            next()
          } else {
            router.push({
              path: '/login',
              name: 'Login',
              query: {
                message: 'You are not authorized to access this page, please login as admin to continue'
              }
            })
          }
        },
        component: () => import('../views/console/Agent.vue'),
        meta: {
          requiredAuth: true,
          title: 'Manage Agent',
        }
      },
      {
        path: 'members',
        name: 'Members',
        component: () => import('../views/console/Member.vue'),
        meta: {
          requiredAuth: true,
          title: 'Manage Members',
        }
      },
      {
        path: 'sessions',
        name: 'Sessions',
        component: () => import('../views/console/Session.vue'),
        meta: {
          requiredAuth: true,
          title: 'Manage Sessions',
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | Member Management`
  next()
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiredAuth)){
    if(window.localStorage.getItem('token') == null){
      next('/login')
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
