import axios from "axios"

export default {
    state: {
        stocks: [],
    },
    getters: {
        allStocks: state => state.stocks,
    },
    mutations: {
        setStocks: (state, stocks) => state.stocks = stocks,
        addStock: (state, stock) => state.stocks.unshift(stock),
        reviseStock: (state, stock) => {
            const index = state.stocks.findIndex(s => s.id === stock.id)
            if (index !== -1) {
                state.stocks.splice(index, 1, stock)
            }
        },
        removeStock: (state, id) => {
            const index = state.stocks.findIndex(s => s.id === id)
            if (index !== -1) {
                state.stocks.splice(index, 1)
            }
        },
    },
    actions: {
        async fetchStocks({commit}){
            const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/member/stocks`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`
                }
            })

            commit("setStocks", response.data)
        },

        async newStock({commit}, stock){
            const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/member/${stock.member_id}/stocks`, stock, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`
                }
            })

            console.log(response.data)
            commit("addStock", response.data)
        },

        async updateStock({commit}, stock){
            const response = await axios.put(`${process.env.VUE_APP_API_URL}/api/member/${stock.member_id}/stocks/${stock.id}`, stock, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`
                }
            })

            commit("reviseStock", response.data)
        },
        
        async deleteStock({commit}, stock){
            await axios.delete(`${process.env.VUE_APP_API_URL}/api/member/${stock.member_id}/stocks/${stock.id}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`
                }
            })

            commit("removeStock", stock.id)
        },
    },
}