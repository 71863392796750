import axios from "axios"

export default {
    state: {
        links: [],
        // memberlinks: []
    },
    getters: {
        allLinks: state => state.links,
        // allMemberLinks: state => state.memberlinks
    },
    mutations: {
        setLinks: (state, links) => state.links = links,
        newLink: (state, link) => state.links.unshift(link),
        reviseLink: (state, link) => {
            const index = state.links.findIndex(l => l.id === link.id)
            if (index !== -1) {
                state.links.splice(index, 1, link)
            }
        },
        removeLink: (state, id) => {
            const index = state.links.findIndex(l => l.id === id)
            if (index !== -1) {
                state.links.splice(index, 1)
            }
        },
    },
    actions: {
        async fetchLinks({commit}){
            const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/member/links`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`
                }
            })

            commit("setLinks", response.data)
        },

        async addLink({commit}, link){
            const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/member/${link.member_id}/links`, link, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`
                }
            })
            console.log(response.data)
            commit("newLink", response.data)
        },

        async updateLink({commit}, link){
            const response = await axios.put(`${process.env.VUE_APP_API_URL}/api/member/${link.member_id}/links/${link.id}`, link, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`
                }
            })

            commit("reviseLink", response.data)
        },

        async deleteLink({commit}, link){
            await axios.delete(`${process.env.VUE_APP_API_URL}/api/member/${link.member_id}/links/${link.id}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`
                }
            })

            commit("removeLink", link.id)
        },
    },
}