import axios from "axios"

export default {
    state: {
        users: []
    },
    getters: {
        allUsers: state => state.users
    },
    mutations: {
        setUsers: (state, users) => state.users = users,
        addUser: (state, user) => state.users.unshift(user),
        reviseUser: (state, user) => {
            const index = state.users.findIndex(u => u.id === user.id)
            if (index !== -1) {
                state.users.splice(index, 1, user)
            }
        },
        removeUser: (state, id) => {
            const index = state.users.findIndex(u => u.id === id)
            if (index !== -1) {
                state.users.splice(index, 1)
            }
        }
    },
    actions: {
        async fetchUsers({commit}){
            const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/users`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`
                }
            })

            commit("setUsers", response.data)
        },

        async newUser({commit}, user){
            const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/register`, user, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`
                }
            })

            commit("addUser", response.data[0].user)
        },

        async updateUser({commit}, user){
            const response = await axios.put(`${process.env.VUE_APP_API_URL}/api/user/${user.id}`, user, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`
                }
            })

            console.log(response.data)
            commit("reviseUser", response.data)
        },

        async deleteUser({commit}, id){
            await axios.delete(`${process.env.VUE_APP_API_URL}/api/user/${id}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`
                }
            })

            commit("removeUser", id)
        },

        async logout(){
            await axios.post(`${process.env.VUE_APP_API_URL}/api/logout`, null, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`
                }
            })
        }
    },
}