import axios from "axios"

export default {
    state: {
        sessions: []
    },
    getters: {
        allSessions: state => state.sessions
    },
    mutations: {
        setSessions(state, sessions) {
            state.sessions = sessions
        },
        addSession(state, session) {
            state.sessions.unshift(session)
        },
        reviseSession(state, session) {
            let index = state.sessions.findIndex(item => item.id === session.id)
            if (index !== -1) {
                state.sessions.splice(index, 1, session)
            }
        },
        removeSession(state, id) {
            let index = state.sessions.findIndex(item => item.id === id)
            if (index !== -1) {
                state.sessions.splice(index, 1)
            }
        }
    },
    actions: {
        async fetchVerifySessions({
            commit
        }) {
            try {
                let res = await axios.get(`${process.env.VUE_APP_API_URL}/api/verification/session`, {
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem("token")}`
                    }
                })
                // console.log(res.data)
                commit("setSessions", res.data)
            } catch (error) {
                console.log(error)
            }
        },

        async addVerifySession({
            commit
        }, session) {
            try {
                let res = await axios.post(`${process.env.VUE_APP_API_URL}/api/verification/session`, session, {
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem("token")}`
                    }
                })

                commit("addSession", res.data)
            } catch (error) {
                console.log(error)
            }
        },

        async updateVerifySession({
            commit
        }, session) {
            try {
                let res = await axios.put(`${process.env.VUE_APP_API_URL}/api/verification/session/${session.id}`, session, {
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem("token")}`
                    }
                })

                commit("reviseSession", res.data)
            } catch (error) {
                console.log(error)
            }
        },

        async deleteVerifySession({
            commit
        }, id) {
            try {
                await axios.delete(`${process.env.VUE_APP_API_URL}/api/verification/session/${id}`, {
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem("token")}`
                    }
                })

                commit("removeSession", id)
            } catch (error) {
                console.log(error)
            }
        }
    },
}